<template>
  <fragment>
    <NavbarVue />
    <div class="container pt-10">
      <div class="row">
        <div class="col-12 col-lg-12 text-white">
          <div class="row">
            <div class="col-12 col-lg-9 col-xl-8 py-5">
              <h1 class="display-4 text-white mb-2 pb-2" v-scroll-reveal>
                Hola, somos Kiiner.
              </h1>
            </div>
          </div>
          <div class="row" v-scroll-reveal>
            <hr class="mt-0 border-white py-4" />
            <div class="col-12 col-lg me-lg-60 me-xl-100 col">
              <p class="lead">
                {{ title }}
              </p>
              <p class="lead">
                {{ summary }}
              </p>
            </div>
            <div class="col-12 col-lg me-lg-60 me-xl-100 col">
              <p class="lead">
                {{ resume_first }}
              </p>
              <p class="lead">
                {{ resume_second }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="justify-content-center row">
        <div class="col-lg-12 text-white pt-4">
          <div class="mt-160">
            <hr class="mt-n6 border-white" />
            <div class="gv-2 justify-content-around text-center row">
              <div class="col-md-auto col-sm-6">
                <div class="number-box">
                  <div class="number-box-title h1 text-white">20</div>
                  <div class="number-box-subtitle">Proyectos</div>
                </div>
              </div>
              <div class="col-md-auto col-sm-6">
                <div class="number-box">
                  <div class="number-box-title h1 text-white">9</div>
                  <div class="number-box-subtitle">años</div>
                </div>
              </div>
            </div>
            <hr class="mt-n6 border-white" />
          </div>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-12 col-lg-12">
          <div class="pt-3 row">
            <div class="col-12 col-lg-9 col-xl-8 py-2">
              <h1 class="display-4 text-white" v-scroll-reveal>
                {{ creative_team.title }}
              </h1>
            </div>
          </div>
          <div class="row">
            <Team :teamList="creative_team.people" />
          </div>
        </div>
      </div>
    </div>
    <FooterVue />
  </fragment>
</template>

<script>
import NavbarVue from "@/components/Navbar.vue";
import FooterVue from "@/components/Footer.vue";
import Team from "@/components/Team.vue";
import Resources from "../services/firebaseresource";
const restResourceService = new Resources();

export default {
  name: "About",
  components: {
    NavbarVue,
    FooterVue,
    Team,
  },
  data() {
    return {
      title: "",
      subtitle: "",
      summary: "",
      resume_first: "",
      resume_second: "",
      experience_items: "",
      creative_team: {},
      showImageIndex: null,
    };
  },
  mounted() {
    restResourceService
      .getLanding("about")
      .then(
        ({
          title,
          subtitle,
          summary,
          resume_first,
          resume_second,
          experience_items,
          creative_team,
        }) => {
          console.log("DEBUG info:", {
            title,
            subtitle,
            summary,
            resume_first,
            resume_second,
            experience_items,
            creative_team,
          });
          this.title = title;
          this.subtitle = subtitle;
          this.summary = summary;
          this.resume_first = resume_first;
          this.resume_second = resume_second;
          this.experience_items = experience_items;
          this.creative_team = creative_team;
        }
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
};
</script>
