<template>
  <div class="our-team">
    <ul class="our-team__list text-white">
      <li
        class="our-team__item"
        v-for="(member, index) in teamList"
        :key="index"
        v-scroll-reveal="{ delay: 50 + index }"
      >
        <span class="our-team__item-content">
          <p class="h3">{{ member.name }}</p>
          <p class="subtitle">{{ member.role }}</p>
        </span>
        <img class="our-team__image" :src="member.image" :alt="member.name" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    teamList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
